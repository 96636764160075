import cloneDeep from 'lodash/cloneDeep';
import {
  SAVE_PREV_ROUTE,

  GET_USER_INFO_STARTING,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,

  LOGIN_CHECK_SUCCESS,
  SET_USER_DATA,

  FORCE_USER_LOGIN,
  LOGOUT_SUCCESS,

  LOGIN_CHECK_STARTING,
  LOGIN_CHECK_ERROR,
  SET_USER_DATA_NONE,

  AUTO_LOGIN_STARTING,
  AUTO_LOGIN_SUCCESS,
  AUTO_LOGIN_ERROR,

  UPDATE_USER_DETAILS_SUCCESS,
} from '../constants/actionTypes';
import initialState from '../store/initialState';
import { clearAuthKeys, updateAuthKeys } from '../axios';

function onUserLoggedIn(data) {
  localStorage.setItem('user', JSON.stringify(data.user));

  if (data.token) {
    localStorage.setItem('token', JSON.stringify(data.token));
    updateAuthKeys();
  }
}

function onUserLoggedOut() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  clearAuthKeys();
}

export default function currentUserReducer(state = initialState.currentUser, action) {
  switch (action.type) {
    case LOGIN_CHECK_STARTING:
    case AUTO_LOGIN_STARTING: {
      const newState = cloneDeep(state);
      newState.loginInProgress = true;
      newState.loggedIn = false;
      newState.error = null;
      newState.loggedInElsewhere = false;
      return newState;
    }

    case LOGIN_CHECK_ERROR:
    case AUTO_LOGIN_ERROR: {
      const newState = cloneDeep(state);
      newState.loginInProgress = false;
      newState.loggedIn = false;
      newState.error = action.err;

      if (action.data && action.data.isLoggedIn) {
        newState.loggedInElsewhere = true;
      }

      return newState;
    }

    case LOGIN_CHECK_SUCCESS:
    case AUTO_LOGIN_SUCCESS: {
      const { data } = action;
      onUserLoggedIn(data);

      const newState = cloneDeep(state);
      newState.userData = data.user;
      newState.tokenData = data.token;
      newState.token = data.token.token;
      newState.userId = data.user.id;
      newState.rolePermissions = data.rolePermissions;

      newState.loginInProgress = false;
      newState.forceLogin = false;
      newState.fetchingInfo = false;
      newState.loggedIn = true;
      newState.error = null;
      newState.loggedInElsewhere = false;

      return newState;
    }

    case GET_USER_INFO_SUCCESS: {
      const { data } = action;
      onUserLoggedIn(data);

      const newState = cloneDeep(state);
      newState.userData = data.user;
      newState.userId = data.user.id;
      newState.rolePermissions = data.rolePermissions;

      newState.loginInProgress = false;
      newState.forceLogin = false;
      newState.fetchingInfo = false;
      newState.loggedIn = true;
      newState.error = null;
      newState.loggedInElsewhere = false;

      return newState;
    }

    case UPDATE_USER_DETAILS_SUCCESS: {
      const { data } = action;
      const newState = cloneDeep(state);
      if (newState.userId === data.user.id) {
        onUserLoggedIn(data);
        newState.userData = data.user;
      }

      return newState;
    }

    case GET_USER_INFO_STARTING: {
      const newState = cloneDeep(state);
      newState.forceLogin = false;
      newState.refetchInfo = false;
      newState.fetchingInfo = true;
      return newState;
    }

    case GET_USER_INFO_ERROR: {
      const newState = cloneDeep(state);
      newState.loginInProgress = false;
      newState.error = action.err;
      newState.loggedIn = false;
      newState.fetchingInfo = false;

      // Force login once again from client
      onUserLoggedOut();
      newState.forceLogin = true;

      return newState;
    }

    case SAVE_PREV_ROUTE: {
      const newState = cloneDeep(state);
      newState.prevRoute = action.prevRoute;
      return newState;
    }

    case FORCE_USER_LOGIN: {
      const newState = cloneDeep(state);
      newState.forceLogin = true;
      return newState;
    }

    case SET_USER_DATA: {
      const { userData, token } = action;
      const newState = cloneDeep(state);
      newState.userData = userData;
      newState.tokenData = token;
      newState.userId = userData.id;
      newState.token = token.token;
      return newState;
    }

    case SET_USER_DATA_NONE: {
      const newState = cloneDeep(state);
      newState.fetchingInfo = false;
      return newState;
    }

    case LOGOUT_SUCCESS: {
      onUserLoggedOut();

      const newState = cloneDeep(initialState.currentUser);
      newState.loggedIn = false;
      newState.loginInProgress = false;
      newState.forceLogin = true;
      newState.fetchingInfo = false;
      newState.error = null;
      newState.loggedInElsewhere = false;

      return newState;
    }

    default: {
      return state;
    }
  }
}
