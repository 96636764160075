import React from 'react';
import {BrowserRouter} from "react-router-dom";
import './styles/styles.scss';
import App from './App';
import configureStore from './store/configureStore';
import {Provider} from 'react-redux';
import {createRoot} from 'react-dom/client';

export const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render((
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
));
